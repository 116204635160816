import i18n from '@/plugins/i18n'

export default {
  selected: null,

  headers: [
    {
      value: "id",
      text: i18n.t("ID")
    },
    {
      value: "customer",
      text: i18n.t("Customer")
    },
    {
      value: "created_at",
      text: i18n.t("Created at")
    },
    {
      value: "method",
      text: i18n.t("Method")
    },
    {
      value: "status",
      text: i18n.t("Status")
    },
    {
      value: "price",
      text: i18n.t("Price")
    },
    {
      value: "qtd",
      text: i18n.t("Qtd")
    },
    {
      value: "amount",
      text: i18n.t("Amount")
    },
    {
      text: i18n.t('Actions'),
      value: 'actions',
      sortable: false,
      width: 100
    }
  ]
}
