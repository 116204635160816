import axios from 'axios'
import store from '@/store'
import router from '@/router'

import AppActions from '@/store/app/actions-types'

let config = {
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
}

const AxiosApp = axios.create(config)

AxiosApp.interceptors.request.use(
  function(config) {
    if (store.state.app.token) {
      config.headers.common['token'] = store.state.app.token
    }

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

AxiosApp.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response) {
      if (error.response.status == 403) {
        router.push('/')
      }

      if (error.response.status == 401 && store.state.app.authenticated) {
        store
          .dispatch('app/' + AppActions.DO_LOGOUT)
          .then(() => {
            router.push('/login')
          })
      }

      if (error.response.data.message) {
        store.dispatch('app/' + AppActions.OPEN_APP_ERROR_MESSAGE, error.response.data.message)
      }
    }

    return Promise.reject(error)
  }
)

export default {
  login: (params) => {
    return AxiosApp.post('auth/login', params)
  },

  create: (module, params) => {
    return AxiosApp.post(module, params)
  },

  update: (module, params, id) => {
    return AxiosApp.put(module + '/' + id, params)
  },

  delete: (module, id) => {
    return AxiosApp.delete(module + '/' + id)
  },

  getOne: (module, id) => {
    return AxiosApp.get(module + '/' + id)
  },

  find: (module, params) => {
    return AxiosApp.get(module, { params })
  },

  uploadFile: (file, origin, originId, onUploadProgress) => {
    let formData = new FormData()

    formData.append("file", file)

    return AxiosApp.post("/content/" + origin + "/" + originId + "/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress
    })
  },

  getDataFromZipCode(zipCode) {
    zipCode = zipCode.replace(/\D/g, '')

    return axios.get(`https://viacep.com.br/ws/${zipCode}/json/`)
  },

  getDoctorDataFromCfm(crm, uf) {
    return AxiosApp.get(`doctor/info/${crm}/${uf}`)
  }
}
