import AppMutations from './mutations-types'

export default {
  [AppMutations.SET_AUTHENTICATED](state, payload) {
    state.authenticated = payload
  },

  [AppMutations.SET_TOKEN](state, token) {
    state.token = token

    if (token) {
        localStorage.setItem('token', token)
        return
    }

    localStorage.removeItem('token')
  },

  [AppMutations.SET_USER_DATA](state, userData) {
    state.userData = userData
  },

  [AppMutations.SET_APP_MESSAGE_TYPE](state, type) {
    state.message.type = type
  },

  [AppMutations.SET_APP_MESSAGE_TEXT](state, text) {
    state.message.text = text
  },

  [AppMutations.SET_APP_MESSAGE_OPEN](state, open) {
    state.message.open = open
  }
}
