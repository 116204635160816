<template>
  <v-container style="max-width:1200px">
    <v-toolbar class="elevation-0">
      <v-toolbar-title style="padding-top:24px">
        <DateRangePicker
          ref="picker"
          :dates="computedDates"
          :append-icon="null"
          prepend-icon="mdi-calendar"
          range
          @set-value="setDateValue"
        ></DateRangePicker>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <span
        v-for="(filter, i) in filters"
        :key="i"
      >
        <v-select
          style="margin-top:25px; margin-left: 10px; max-width:180px"
          v-if="filter.type == 'select'"
          :items="filter.items"
          v-model="filter.value"
          :label="filter.label"
          :item-value="filter.itemValue"
          :item-text="filter.itemText"
          @change="$emit(filter.event, filter)"
          clearable
          outlined
          dense
        ></v-select>
      </span>

    </v-toolbar>

    <v-divider style="margin-bottom:18px"></v-divider>

    <v-row>
      <v-col v-if="isMaster() || isFinancial()" cols="4">
        <v-card height="120" elevation="0">
          <v-card-text>
            <v-item>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon x-large color="green">mdi-currency-usd</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('Total revenue') }}</v-list-item-subtitle>
                  <v-list-item-title class="text-h4">{{ convertPriceValue(totalRevenue) | VMask(priceMask) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-item>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col :cols="(isMaster() || isFinancial()) ? 4 : 6">
        <v-card height="120" elevation="0">
          <v-card-text>
            <v-item>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon x-large color="blue">mdi-check-underline</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('Paid packages') }}</v-list-item-subtitle>
                  <v-list-item-title class="text-h4">{{ paidPackages }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-item>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col :cols="(isMaster() || isFinancial()) ? 4 : 6">
        <v-card height="120" elevation="0">
          <v-card-text>
            <v-item>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon x-large color="orange">mdi-account</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('Vouchers delivered') }}</v-list-item-subtitle>
                  <v-list-item-title class="text-h4">{{ deliveredVouchers }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-item>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card elevation="0" style="height:380px; padding-top:10px">
          <v-flex v-if="loading" xs12>
            <v-layout align-center column>
              <v-progress-circular
                indeterminate
                color="primary"
                style="margin-top:154px"
              ></v-progress-circular>
            </v-layout>
          </v-flex>

          <LineChart
            v-if="!loading"
            :chart-options="chartOptions"
            :chart-data="chartData"
            :height="120"
          ></LineChart>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Vue from 'vue'
import api from '@/services/api'
import i18n from '@/plugins/i18n.js'
import { DateTime } from "luxon"
import DateRangePicker from '@/components/DateRangePicker'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { Line as LineChart } from 'vue-chartjs/legacy'
import { Chart as ChartJS, registerables } from 'chart.js'
import { mapState } from 'vuex'

ChartJS.register(...registerables)

export default {
  name: 'Dashboard',

  components: {
    DateRangePicker, LineChart
  },

  mounted() {
    this.start = DateTime.now().minus({months: 1}).setLocale(i18n.locale)
    this.finish = DateTime.now().setLocale(i18n.locale)
    this.$refs.picker.setValue([this.start.toFormat('yyyy-MM-dd'), this.finish.toFormat('yyyy-MM-dd')])

    this.loadItems()
  },

  watch: {
    filters: {
      handler () {
        if (!this.loading) {
          this.loadData()
        }
      },
      deep: true
    }
  },

  data() {
    return {
      loading: 0,
      start: null,
      finish: null,
      data: {},
      filters: [
        {
          type: 'select',
          items: [],
          value: null,
          name: 'u.id',
          event: 'filter',
          label: i18n.t('Unit'),
          itemText: 'name',
          itemValue: 'id'
        },
        {
          type: 'select',
          items: [],
          value: null,
          name: 'r.id',
          event: 'filter',
          label: i18n.t('Product'),
          itemText: 'title',
          itemValue: 'id'
        },
        {
          type: 'select',
          items: [],
          value: null,
          name: 'e.id',
          event: 'filter',
          label: i18n.t('Event'),
          itemText: 'title',
          itemValue: 'id'
        }
      ],
      priceMask: createNumberMask({
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
        thousandsSeparatorSymbol: i18n.t('thousandSeparatorSymbol'),
        decimalSymbol: i18n.t('decimalSymbol'),
        prefix: i18n.t('currencyPrefix')
      }),
      chartOptions: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    }
  },

  computed: {
    ...mapState({
      userData: state => state.app.userData
    }),

    computedDates() {
      if (!this.start || !this.finish) {
        return []
      }

      return [this.start.toFormat('yyyy-MM-dd'), this.finish.toFormat('yyyy-MM-dd')]
    },

    totalRevenue() {
      return !this.data || !this.data.total ? 0 : this.data.total
    },

    paidPackages() {
      return !this.data || !this.data.qtd ? 0 : this.data.qtd
    },

    deliveredVouchers() {
      return !this.data || !this.data.delivered ? 0 : this.data.delivered
    },

    chartData() {
      if (!this.start || !this.finish || !this.data || !this.data.revenue_data) {
        return {}
      }

      let diff = this.finish.diff(this.start, 'days')

      let start = DateTime.local(this.start.year, this.start.month, this.start.day).setLocale(i18n.locale)

      let labels = []
      let totalRevenue = []
      let totalQtd = []
      let totalDelivered = []

      for (let x = 0; x <= diff.days; x++) {
        if (x == 0 || x == diff.days) {
          labels.push(start.toLocaleString())
        } else {
          labels.push(start.toFormat('dd/MM'))
        }

        start = start.plus({days: 1})

        let revenue = 0
        let qtd = 0
        let delivered = 0

        let item = this.data.revenue_data.find(revenue => revenue.dt == start.toFormat('yyyy-MM-dd'))

        if (item) {
          revenue = item.amount
          qtd = item.qtd
        }

        item = this.data.voucher_data.find(voucher => voucher.dt == start.toFormat('yyyy-MM-dd'))

        if (item) {
          delivered = item.qtd
        }

        totalRevenue.push(revenue)
        totalQtd.push(qtd)
        totalDelivered.push(delivered)
      }

      let datasets = []

      if (this.isMaster() || this.isFinancial()) {
        datasets.push({label: this.$t('Total revenue'), data: totalRevenue, borderColor: '#4CAF50', backgroundColor: '#4CAF50'})
      }

      datasets.push({label: this.$t('Paid packages'), data: totalQtd, borderColor: '#2196F3', backgroundColor: '#2196F3'})
      datasets.push({label: this.$t('Vouchers delivered'), data: totalDelivered, borderColor: '#ff9800', backgroundColor: '#ff9800'})

      return {
        labels: labels,
        datasets: datasets
      }
    }
  },

  methods: {
    isMaster() {
      return this.userData.roles.find(role => role == 'ROLE_MASTER') ? true : false
    },

    isFinancial() {
      return this.userData.roles.find(role => role == 'ROLE_MASTER') ? true : false
    },

    loadItems() {
      api
        .find('unit', {order: 'name', limit: 100, "filters[active]": 1})
        .then((response) => {
          Vue.set(this.filters[0], 'items', response.data.results)
        })

      api
        .find('product', {order: 'title', limit: 100, "filters[active]": 1})
        .then((response) => {
          Vue.set(this.filters[1], 'items', response.data.results)
        })

      api
        .find('event', {order: 'start_at', limit: 100, "filters[active]": 1})
        .then((response) => {
          let events = []

          response.data.results.forEach(evt => events.push(
            {id: evt.id, title: this.parseDateValue(evt.start_at) + ' - ' + this.parseDateValue(evt.finish_at)}
          ))

          Vue.set(this.filters[2], 'items', events)
        })

      this.loadData()
    },

    loadData() {
      let params = {
        start_date: this.start.toFormat('yyyy-MM-dd'),
        finish_date: this.finish.toFormat('yyyy-MM-dd')
      }

      if (this.filters[0].value) {
        params['filters[unit]'] = this.filters[0].value
      }

      if (this.filters[1].value) {
        params['filters[product]'] = this.filters[1].value
      }

      if (this.filters[2].value) {
        params['filters[event]'] = this.filters[2].value
      }

      this.loading++

      api
        .find('report/dashboard', params)
        .then((response) => {
          Vue.set(this, 'data', response.data)

          this.loading--
        })
    },

    parseDateValue(dateValue) {
      return DateTime.fromISO(dateValue).setLocale(i18n.locale).toLocaleString()
    },

    convertPriceValue(price) {
      if (!price) {
        return price;
      }

      return price
        .toFixed(2)
        .toString()
        .replace('.', i18n.t("decimalSymbol"))
    },    

    setDateValue(value) {
      this.start = DateTime.fromFormat(value[0], 'yyyy-MM-dd')
      this.finish = DateTime.fromFormat(value[1], 'yyyy-MM-dd')

      this.loadData()
    }
  }
}
</script>
