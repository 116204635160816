import AppActions from './actions-types'
import AppMutations from './mutations-types'
import api from '@/services/api'
import jwt from '@/services/jwt'

export default {
  [AppActions.DO_LOGIN]({ dispatch }, credentials) {
    return new Promise((resolve, reject) => {

      api
        .login(credentials)
        .then((response) => {
          dispatch(AppActions.SAVE_LOGIN_DATA, response.data.token)
            .then(() => {
              resolve()
            })
            .catch((error) => reject(error))
        })
        .catch((error) => {
          reject(error.response.data);
        })
    })
  },

  [AppActions.DO_LOGOUT]({ dispatch }) {
    return new Promise((resolve) => {
      dispatch(AppActions.RESET_LOGIN_DATA)

      resolve()
    })
  },

  [AppActions.SAVE_LOGIN_DATA]({ commit, dispatch }, token) {
    return new Promise((resolve, reject) => {
      jwt
        .decode(token)
        .then((decoded) => {
          commit(AppMutations.SET_AUTHENTICATED, true)
          commit(AppMutations.SET_TOKEN, token)
          commit(AppMutations.SET_USER_DATA, decoded.data)

          resolve()
        })
        .catch((error) => {
          dispatch(AppActions.RESET_LOGIN_DATA)

          reject(error)
        })
    })
  },

  [AppActions.RESET_LOGIN_DATA]({ commit }) {
    commit(AppMutations.SET_AUTHENTICATED, false)
    commit(AppMutations.SET_TOKEN, null)
    commit(AppMutations.SET_USER_DATA, {})
  },


  [AppActions.CHECK_TOKEN]({ dispatch, state }) {
    return new Promise((resolve, reject) => {
      if (state.authenticated) {
        resolve()
        return
      }

      const token = localStorage.getItem('token')

      if (!token) {
        resolve()
        return
      }

      dispatch(AppActions.SAVE_LOGIN_DATA, token)
        .then(() => resolve())
        .catch((error) => reject(error))
    })
  },

  [AppActions.OPEN_APP_ERROR_MESSAGE]({ commit }, message) {
    commit(AppMutations.SET_APP_MESSAGE_TEXT, message)
    commit(AppMutations.SET_APP_MESSAGE_TYPE, 'error')
    commit(AppMutations.SET_APP_MESSAGE_OPEN, true)
  },

  [AppActions.OPEN_APP_SUCCESS_MESSAGE]({ commit }, message) {
    commit(AppMutations.SET_APP_MESSAGE_TEXT, message)
    commit(AppMutations.SET_APP_MESSAGE_TYPE, 'success')
    commit(AppMutations.SET_APP_MESSAGE_OPEN, true)
  }
}