<template>
  <v-menu
    v-model="menuDatePicker"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        style="padding-top:0"
        :value="formatedValue"
        :label="label"
        :append-icon="appendIcon"
        :prepend-inner-icon="prependIcon"
        readonly
        v-bind="attrs"
        v-on="on"
        :rules="rules"
        :outlined="outlined"
        dense
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="originalValue"
      :locale="locale"
      range
      color="primary"
    ></v-date-picker>
  </v-menu>

</template>

<script>

import i18n from '@/plugins/i18n'
import { DateTime } from "luxon"

export default {
  name: 'DateRangePicker',

  props: {
    fieldName: String,
    label: String,
    value: String,
    rules: Array,
    range: Boolean,
    dates: Array,
    prependIcon: {
      type: String,
      default: ''
    },
    appendIcon: {
      type: String,
      default: 'mdi-calendar'
    },
    outlined: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    menuDatePicker: false,
    originalValue: [],
    firstSet: true
  }),

  mounted() {
    this.originalValue = this.dates
  },

  watch: {
    originalValue: {
      handler() {
        if (this.originalValue.length == 2) {
          this.menuDatePicker = false

          if (this.firstSet) {
            this.firstSet = false
            return
          }

          this.$emit('set-value', this.originalValue)          
        }
      }
    }
  },

  computed: {
    locale() {
      return i18n.locale
    },

    formatedValue() {
      if (this.originalValue.length < 2) {
        return null
      }

      let date1 = DateTime.fromISO(this.originalValue[0]).setLocale(i18n.locale).toLocaleString()
      let date2 = DateTime.fromISO(this.originalValue[1]).setLocale(i18n.locale).toLocaleString()

      return date1 + ' - ' + date2
    },
  },

  methods: {
    setValue(value) {
      this.originalValue = value
    }
  }
}
</script>
