<template>
  <v-app style="background-color:#f3f3f3">
    <v-navigation-drawer
      v-if="authenticated"
      v-model="drawer"
      app
      clipped
      :permanent="!$vuetify.breakpoint.mobile"
      :expand-on-hover="!$vuetify.breakpoint.mobile"
      :mini-variant="!$vuetify.breakpoint.mobile"
    >
        <v-list nav>
          <v-list-item
            v-for="item in availableMenuItems"
            :key="item.title"
            v-model="item.active"
            active-class="active-nav-group"
            :to="item.href"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="$t(item.title)"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="#ffffff"
      v-if="authenticated"
      elevation="0"
      height="80px"
      clipped-left
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="hidden-md-and-up"
      ></v-app-bar-nav-icon>

      <v-toolbar-title
        class="title-img"
      >
        <v-img
          alt="NR Memories Logo"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          src="./assets/logo_nrmemories.png"
          height="68"
        />

      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn @click="logout" text>sair</v-btn>
    </v-app-bar>

    <v-snackbar
      :timeout="message.timeout"
      :value="message.open"
      bottom
      style="margin-bottom:20px"
      :color="message.type"
      v-on:input="messageChanged"
    >
      <v-icon v-if="message.type == 'error'">mdi-alert</v-icon>
      <v-icon v-if="message.type == 'success'">mdi-check-circle</v-icon>

      {{ message.text }}

      <v-spacer></v-spacer>

      <template v-slot:action>
        <v-btn
          color="white"
          text
          @click="closeAlert()"
        >
          {{ $t('close') }}
        </v-btn>
      </template>

    </v-snackbar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<style>
  .title-img {
    padding-left: 25px !important;
  }
</style>

<script>

import { mapActions, mapMutations, mapState } from 'vuex'
import router from "@/router"

import AppActions from '@/store/app/actions-types'
import AppMutations from '@/store/app/mutations-types'

export default {
  name: 'App',

  computed: {
    ...mapState({
      authenticated: state => state.app.authenticated,
      userData: state => state.app.userData,
      menuItems: state => state.app.menuItems,
      message: state => state.app.message
    }),

    availableMenuItems() {
      return this.menuItems.filter(
        i => i.roles.some(
          r => this.userData.roles.includes(r)
        )
      )
    }
  },

  data: () => ({
    drawer: true
  }),

  methods: {
     ...mapActions('app', [
      AppActions.DO_LOGOUT
    ]),

    ...mapMutations('app', [
      AppMutations.SET_APP_MESSAGE_OPEN
    ]),

    logout() {
      this[AppActions.DO_LOGOUT]()
        .then(() => router.push('/login'))
    },

    closeAlert() {
      this[AppMutations.SET_APP_MESSAGE_OPEN](false)
    },

    messageChanged() {
      this[AppMutations.SET_APP_MESSAGE_OPEN](false)
    }
  }
};
</script>
