import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import AppActions from '@/store/app/actions-types'

import Dashboard from '../views/Dashboard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      public: true,
      onlyLoggedOut: true
    }
  },
  {
    path: '/users',
    name: 'User',
    component: () => import('../views/Users.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false
    }
  },
  {
    path: '/units',
    name: 'Unit',
    component: () => import('../views/Unit.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false
    }
  },
  {
    path: '/products',
    name: 'Product',
    component: () => import('../views/Product.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false
    }
  },
  {
    path: '/events',
    name: 'Event',
    component: () => import('../views/Event.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false
    }
  },
  {
    path: '/packages',
    name: 'Package',
    component: () => import('../views/Package.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false
    }
  },
  {
    path: '/vouchers',
    name: 'Voucher',
    component: () => import('../views/Voucher.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false
    }
  },
  {
    path: '/payments',
    name: 'Payment',
    component: () => import('../views/Payment.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false
    }
  },
  {
    path: '/students',
    name: 'Student',
    component: () => import('../views/Student.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false
    }
  },
  {
    path: '/export',
    name: 'Export',
    component: () => import('../views/Export.vue'),
    meta: {
      public: false,
      onlyLoggedOut: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  store
    .dispatch('app/' + AppActions.CHECK_TOKEN)
    .then(() => {
      const authenticated = store.state.app.authenticated
      const onlyLoggedOut = to.matched.some(record => record.meta.onlyLoggedOut)
      const isPublic = to.matched.some(record => record.meta.public)

      if (!isPublic && !authenticated) {
        return next({
          path: '/login',
          query: { redirect: to.fullPath }
        })
      }

      let isAdmin = false
      let isMaster = false
      let isFinancial = false

      if (authenticated) {
        isAdmin = store.state.app.userData.roles.find(role => role == 'ROLE_ADMIN') ? true : false
        isMaster = store.state.app.userData.roles.find(role => role == 'ROLE_MASTER') ? true : false
        isFinancial = store.state.app.userData.roles.find(role => role == 'ROLE_FINANCIAL') ? true : false
      }

      if (authenticated && onlyLoggedOut) {
        return next('/')
      }

      if (authenticated && to.name == 'Dashboard' && !isMaster && !isFinancial && !isAdmin) {
        return next('/students')
      }

      next()
    })
    .catch(() => {
      return next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    })
})

export default router
