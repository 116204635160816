import i18n from '@/plugins/i18n'
import rules from '@/services/form_rules'

export default {
  selected: null,

  fields: [
    {
      name: "id",
      label: i18n.t("ID"),
      hideInForm: true
    },
    {
      name: "email",
      label: i18n.t("Email"),
      type: "text",
      rules: rules.required
    },
    {
      name: "roles",
      label: i18n.t("Roles"),
      type: "select",
      itemText: 'title',
      itemValue: 'role',
      multiple: true,
      items: [
        {'title': 'Master', 'role': 'ROLE_MASTER'},
        {'title': 'Financeiro', 'role': 'ROLE_FINANCIAL'}
       ]
    },
    {
      name: "admins",
      label: i18n.t("Administrate units"),
      type: 'select',
      itemText: 'name',
      itemValue: 'id',
      items: [],
      hideOnTable: true,
      multiple: true
    },
    {
      name: "active",
      label: i18n.t("Active"),
      type: "switch"
    }
  ]
}
