export default {
  authenticated: false,
  token: null,
  userData: {},
  message: {
    open: false,
    type: null,
    text: null,
    timeout: 10000
  },
  menuItems: [
    {
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      href: '/',
      roles: ['ROLE_MASTER', 'ROLE_ADMIN', 'ROLE_FINANCIAL']
    },
    {
      title: 'Payments',
      icon: 'mdi-credit-card-multiple-outline',
      href: '/payments',
      roles: ['ROLE_MASTER', 'ROLE_FINANCIAL']
    },
    {
      title: 'Students',
      icon: 'mdi-account-group',
      href: '/students',
      roles: ['ROLE_MASTER', 'ROLE_ADMIN', 'ROLE_FINANCIAL']
    },
    {
      title: 'Export',
      icon: 'mdi-database-export',
      href: '/export',
      roles: ['ROLE_MASTER', 'ROLE_ADMIN', 'ROLE_FINANCIAL']
    },
    {
      title: 'Products',
      icon: 'mdi-file-star',
      href: '/products',
      roles: ['ROLE_MASTER', 'ROLE_ADMIN', 'ROLE_FINANCIAL']
    },
    {
      title: 'Units',
      icon: 'mdi-fireplace-off',
      href: '/units',
      roles: ['ROLE_MASTER', 'ROLE_ADMIN', 'ROLE_FINANCIAL']
    },
    {
      title: 'Events',
      icon: 'mdi-calendar',
      href: '/events',
      roles: ['ROLE_MASTER', 'ROLE_ADMIN', 'ROLE_FINANCIAL']
    },
    {
      title: 'Packages',
      icon: 'mdi-package',
      href: '/packages',
      roles: ['ROLE_MASTER', 'ROLE_FINANCIAL']
    },
    {
      title: 'Vouchers',
      icon: 'mdi-led-strip',
      href: '/vouchers',
      roles: ['ROLE_MASTER', 'ROLE_FINANCIAL']
    },
    {
      title: 'Users',
      icon: 'mdi-account-multiple-outline',
      href: '/users',
      roles: ['ROLE_MASTER']
    }
  ]
}
