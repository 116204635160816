import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import event from './event'
import pack from './package'
import payment from './payment'
import product from './product'
import student from './student'
import unit from './unit'
import user from './user'
import voucher from './voucher'

Vue.use(Vuex)

const store = {
  modules: {
    app,
    event,
    pack,
    payment,
    product,
    student,
    unit,
    user,
    voucher
  }
}

export default new Vuex.Store(store)
