import i18n from '@/plugins/i18n'
import rules from '@/services/form_rules'

export default {
  selected: null,

  fields: [
    {
      name: "id",
      label: i18n.t("ID"),
      hideInForm: true
    },
    {
      name: "name",
      label: i18n.t("Name"),
      type: "text",
      rules: rules.required
    },
    {
      name: "active",
      label: i18n.t("Active"),
      type: "switch"
    }
  ]
}
