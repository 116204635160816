import i18n from '@/plugins/i18n'
import rules from '@/services/form_rules'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const numberMask = createNumberMask({
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  thousandsSeparatorSymbol: i18n.t('thousandSeparatorSymbol'),
  decimalSymbol: i18n.t('decimalSymbol'),
  prefix: ''
})

export default {
  selected: null,

  headers: [
    {
      value: "id",
      text: i18n.t("ID")
    },
    {
      value: "title",
      text: i18n.t("Product")
    },
    {
      value: "price",
      text: i18n.t("Price")
    },
    {
      value: "packages",
      text: i18n.t("Qtd of packages"),
      sortable: false
    },
    {
      text: i18n.t('Actions'),
      value: 'actions',
      sortable: false,
      width: 160
    }
  ],

  fields: [
    {
      name: "id",
      label: i18n.t("ID")
    },
    {
      name: "product",
      label: i18n.t("Product"),
      type: "select",
      rules: rules.required,
      itemText: 'title',
      itemValue: 'id',
      items: []
    },
    {
      name: "qtd",
      label: i18n.t("Qtd"),
      type: "number",
      rules: rules.required,
      cols: 4
    },
    {
      name: "value",
      label: i18n.t("Value"),
      type: "text",
      mask: numberMask,
      rules: rules.required,
      cols: 4
    },
    {
      name: "type",
      label: i18n.t("Value type"),
      type: "select",
      rules: rules.required,
      itemText: 'text',
      itemValue: 'value',
      items: [
        {value: 'fix', text: i18n.t('Fixed value')},
        {value: 'percent', text: i18n.t('Percent')}
      ],
      cols: 4
    }
  ]
}
