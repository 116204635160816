import i18n from '@/plugins/i18n'
import rules from '@/services/form_rules'

export default {
  selected: null,

  fields: [
    {
      name: "id",
      label: i18n.t("ID"),
      hideInForm: true
    },
    {
      name: "code",
      label: i18n.t("Code"),
      type: "textarea",
      rules: rules.required
    },
    {
      name: "student",
      label: i18n.t("Student"),
    },
    {
      name: "status",
      label: i18n.t("Status"),
      type: "select",
      rules: rules.required,
      hide: true,
      itemText: 'text',
      itemValue: 'value',
      items: [
        {value: 'available', text: i18n.t('Available')},
        {value: 'unvailable', text: i18n.t('Unvailable')},
        {value: 'canceled', text: i18n.t('Canceled')},
        {value: 'delivered', text: i18n.t('Delivered')},
        {value: 'reserved', text: i18n.t('Reserved')}
      ]
    }
  ]
}
