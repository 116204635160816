import i18n from '@/plugins/i18n'
import rules from '@/services/form_rules'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const numberMask = createNumberMask({
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  thousandsSeparatorSymbol: i18n.t('thousandSeparatorSymbol'),
  decimalSymbol: i18n.t('decimalSymbol'),
  prefix: i18n.t('currencyPrefix')
})

export default {
  selected: null,

  fields: [
    {
      name: "id",
      label: i18n.t("ID"),
      hideInForm: true
    },
    {
      name: "start_at",
      label: i18n.t("Start at"),
      type: "date",
      rules: rules.required,
      autofocus: true,
      cols: 6
    },
    {
      name: "finish_at",
      label: i18n.t("Finish at"),
      type: "date",
      rules: rules.required,
      cols: 6
    },
    {
      name: "unit",
      label: i18n.t("Unit"),
      type: "select",
      autofocus: true,
      rules: rules.required,
      itemText: 'name',
      itemValue: 'id'
    },
    {
      name: "product",
      label: i18n.t("Product"),
      type: "select",
      autofocus: true,
      rules: rules.required,
      itemText: 'title',
      itemValue: 'id'
    },
    {
      name: "price",
      label: i18n.t("Price"),
      type: "text",
      mask: numberMask
    },
    {
      name: "active",
      label: i18n.t("Active"),
      type: "switch"
    },
    {
      name: "id_fotop",
      label: i18n.t("ID Fotop"),
      type: "number"
    }
  ]
}
