import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'
import i18n from './i18n'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: colors.blue.darken1,
        secondary: colors.green.lighten1,
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        appBar: '#000000',
        avatarBg: colors.blueGrey.darken1,
        contentBg: '#FF0000',
        cancelButton: '#A0A0A0',
        submitButton: '#4cab3d',
        deleteButton: colors.red.darken1
      }
    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  }
});
