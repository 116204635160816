import i18n from '@/plugins/i18n'
import rules from '@/services/form_rules'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const numberMask = createNumberMask({
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  thousandsSeparatorSymbol: i18n.t('thousandSeparatorSymbol'),
  decimalSymbol: i18n.t('decimalSymbol'),
  prefix: i18n.t('currencyPrefix')
})

export default {
  selected: null,

  fields: [
    {
      name: "id",
      label: i18n.t("ID"),
      hideInForm: true
    },
    {
      name: "title",
      label: i18n.t("Title"),
      type: "text",
      rules: rules.required,
      autofocus: true
    },
    {
      name: "description",
      label: i18n.t("Description"),
      type: "textarea",
      hideOnTable: true
    },
    {
      name: "slug",
      label: i18n.t("Slug"),
      type: "text",
      rules: rules.required
    },
    {
      name: "price",
      label: i18n.t("Price"),
      type: "text",
      mask: numberMask,
      rules: rules.required
    },
    {
      name: "vacation_season",
      label: i18n.t("Vacation season"),
      type: "switch"
    },
    {
      name: "active",
      label: i18n.t("Active"),
      type: "switch"
    }
  ]
}
