import i18n from '@/plugins/i18n'

export default {
  selected: null,

  headers: [
    {
      value: "id",
      text: i18n.t("ID")
    },
    {
      value: "r.title",
      text: i18n.t("Product")
    },
    {
      value: "u.name",
      text: i18n.t("Unit")
    },
    {
      value: "e.start_at",
      text: i18n.t("Event")
    },
    {
      value: "fullname",
      text: i18n.t("Student")
    },
    {
      value: "c.group",
      text: i18n.t("School or group")
    },
    {
      value: "p.id",
      text: i18n.t("Payment ID")
    },
    {
      value: "c.fullname",
      text: i18n.t("Customer")
    },
    {
      value: "p.updated_at",
      text: i18n.t("Updated at")
    },
    {
      value: "v.status",
      text: i18n.t("Voucher delivered")
    },
    {
      text: i18n.t('Actions'),
      value: 'actions',
      sortable: false,
      width: 160
    }
  ]
}
