import jwt_decode from 'jwt-decode'

export default {
  decode: (token) => {
    return new Promise((resolve, reject) => {
      const decoded = jwt_decode(token)

      const now = new Date().getTime() / 1000 | 0

      if (decoded.exp - now < 0) {
        reject('expired_token')
        return
      }

      resolve(decoded)
    })
  }
}